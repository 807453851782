<template>
  <div>
    <NavBar
    style="max-height:50px;margin-bottom: 10px;"
    :username="currentUserName"
    :pageName="pageName"
    ></NavBar>

    <v-app>
      <div style="background-color: #f5faff; height: 93vh !important;">
        <v-row cols="12">
          <v-col cols="2">
          <SideBar style="max-height:50px;" :pageName="pageName"></SideBar>
          </v-col>
          <v-col cols="10">
            <div style="margin: 10px 20px 20px 50px ">
             <div
              style="
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              vertical-align: middle;
              "
              >
            <div style = "width:30%" align="left">
            <span style="font-weight: 800;padding-left: 5px; font-size:12pt;text-transform: uppercase;letter-spacing: 1px;">
           <h3 >Edit Slots</h3></span>
            </div></div>
            <!-- <h3 style="text-align:left; margin-left:65px; margin-top:30px;font-weight:800; font-size: 12pt; text-transform: uppercase;   letter-spacing: 1px;">Edit Slots</h3> -->
            <div style="display: flex; flex-direction: row; margin: 20px -12px;width:90%;">
            <v-card style="width:60%; margin: 16px; padding: 16px 32px;height: 70vh; overflow:scroll; overflow-x: hidden;">
              <div style=" width: 100%; display: flex; flex-direction: column; margin-top: 20px; ">
                <div style="display: flex; flex-direction: row; ">
                  <div style="width: 100%; font-weight: 600;" align="left">
                   <p style="margin-top:12px"> Specialization</p>
                  </div>
                  <div style="width: 100%">
                    <v-select
                      v-model="selected_specialization"
                      solo
                      label="Specialization"
                      :items = "specialization_list"
                      item-text = "specialization_name"
                      item-value = "_id"
                      return-object
                      @change = 'getDoctorList'
                      >
                    </v-select>
                  </div>
                </div>
                <div style="display: flex; flex-direction: row; ">
                  <div 	style="width: 100%; font-weight: 600;" align="left">
                   <p style="margin-top:12px"> Doctor Name</p>
                  </div>
                  <div style="width: 100%">
                    <v-select
                      v-model="selected_doctor"
                      solo
                      label="Doctor"
                      :items = "doctors_list"
                      item-text = "doctor_name"
                      item-value = "_id"
                      return-object
                      @change = 'getPreviousSlots'
                      >
                    </v-select>
                  </div>
                </div>
                <div style="display: flex; flex-direction: row;" v-if="selected_doctor">
                  <div 	style="width: 100%; font-weight: 600;" align="left">
                    <p style="margin-top:12px"> Date</p>
                  </div>
                  <div style="width: 100%">
                    <v-menu ref="menu" v-model="menu" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto" >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field v-model="start_gen_date" label="Dates List" background-color="#F4F6FA" outlined v-bind="attrs" v-on="on" style="border-radius:8px;" ></v-text-field>
                      </template>
                      <v-date-picker style="padding:20px; height: 500px;color: black" v-model="start_gen_date" show-adjacent-months :allowed-dates="allowedDates"></v-date-picker>
                    </v-menu>
                  </div>
                </div>
                <div style="display: flex; flex-direction: row">
                  <div style="width: 50%; font-weight: 600" align="left">
                    <p style="margin-top:12px"> Slot Starting Time</p>
                  </div>
                  <div style="width: 50%">
                    <v-select :items="open_time" v-model="start_time" label="Starting Time" solo >
                    </v-select>
                  </div>
                </div>
                <div style="display: flex; flex-direction: row">
                  <div style="width: 50%; font-weight: 600" align="left">
                    <p  style="margin-top:12px"> Slot Ending Time</p>
                  </div>
                  <div style="width: 50%">
                    <v-select :items="close_time" v-model="end_time" label="Closing Time" solo style="width: 100%" >
                    </v-select>
                  </div>
                </div>
                <div v-if="appointment_duration !== ''" style="display: flex; flex-direction: row">
                  <div style="width: 50%; font-weight: 600" align="left">
                    <p style="margin-top:12px;"> Appointment Duration</p>
                  </div>
                  <div style="width: 50%">
                    {{appointment_duration}}
                  </div>
                </div>
                <div v-if="break_duration !== ''" style="display: flex; flex-direction: row">
                  <div style="width: 50%; font-weight: 600" align="left">
                    <p style="margin-top:12px;"> Break interval</p>
                  </div>
                  <div style="width: 50%">
                    {{break_duration}}
                  </div>
                </div>
                <div  style="margin-top:20px" align="right">
                  <v-btn style="width: 40%;" color="primary" @click="generate_slots" :disabled="!start_time && !end_time && !selected_doctor" >Show Slots</v-btn>
                </div>
              </div>
            </v-card>
            <v-card style="width:40%; display:flex; flex-wrap:wrap; margin: 16px 0px;height: 70vh;">
              <div v-if="show_old_slots" style="width: 100%;display: flex;flex-direction: column;">
                <div style="font-weight: 800; padding-top:36px; margin-bottom:20px">Previously generated slots </div>
                <div>
                  <div style="display: flex;flex-direction: row;flex-wrap: wrap;">
                    <div v-for="slot in old_slots" :key="slot" style="margin-bottom:2%;padding:6px; margin: 8px;" align="center" >
                      <div style="padding:0px; border-radius: 24px; line-height: 18px; font-weight: 600;" v-bind:class="slot.selected == 1 ? 'active' : 'inactive'">{{ slot }}</div>
                    </div>
                  </div>
                </div>
              </div>

              <div v-if="generated_slots.length > 0" style="width: 100%;display: flex;flex-direction: column;">
                <div style="display: flex; flex-direction: row; margin: 15px;justify-content: space-between;">

                  <div align="left" style="font-weight: 800;">
                    Please select available slots
                  </div>
                  <div
                    align="center">
                     <div v-if="selected_all">
                    <v-btn @click="unselect_all" class="success">Unselect All</v-btn>
                  </div>

                  <div style="margin: auto;" v-else>
                    <v-btn @click="select_all" class="error">Select All</v-btn>
                  </div>

                  </div>
                </div>
                <div style="overflow: scroll; height: 50vh;">
                  <div style="display: flex;flex-direction: row;flex-wrap: wrap;">
                    <div
                    v-for="slot in generated_slots"
                    :key="slot.time_slot"
                    style="margin-bottom:2%;padding:16px; margin: 8px;"
                    @click="toggle(slot)"
                    align="center"
                    >
                      <div style="padding:15px; border-radius: 24px; line-height: 28px; font-weight: 600;" v-bind:class="checkClass(slot)">{{ slot.time_slot }}</div>
                    </div>
                  </div>
                </div>
                <v-btn style="bottom: 0px; position: absolute; width: 100%" color="success" @click="create_slots" :disabled="generate_slots_clicked" >Update Slots</v-btn>
              </div>
            </v-card>
            </div>
         </div> </v-col>
        </v-row>
      </div>
      <div align="center">
      </div>
    </v-app>
  </div>
</template>


<script>
import axios from "axios";
import NavBar from "../../../views/navbar.vue";
import SideBar from "../../../views/sideBar.vue";
const bookingSlotGenerator = require('booking-slot-generator')
import moment from 'moment';
import DatePicker from 'vue2-datepicker';
import { axios_auth_instance_admin } from '../../../utils/axios_utils';
import { handleError } from '../../../utils/utils';
export default {
  name: "CustomerHome",
  data() {
    return {
      start_gen_date: null,
      menu: false,
      end_gen_date: "",
      start_time: "",
      end_time: "",
      counter: 0,
      open_time: ["09:00", "09:30", "10:00", "10:30", "11:00", "11:30", "12:00", "12:30", "13:00", "13:30", "14:00", "14:30", "15:00", "15:30", "16:00", "16:30", "17:00", "17:30", "18:00", "18:30", "19:00", "19:30", "20:00"],
      close_time: ["10:00", "10:30", "11:00", "11:30", "12:00", "12:30", "13:00", "13:30", "14:00", "14:30", "15:00", "15:30", "16:00", "16:30", "17:00", "17:30", "18:00", "18:30", "19:00", "19:30", "20:00", "20:30", "21:00","21:30", "22:00","22:30", "23:00","23:30", "24:00"],
      prev_slots: [],
      currentUser: "",
      currentUserName: "",
      pageName: "EditSlotsPage",
      generated_slots: [],
      doctors_list: [],
      selected_doctor: "",
      appointment_duration: "",
      break_duration: "",
      old_slots: [],
      show_old_slots: false,
      appointmentDurationRules : [v=> v >= 0 || "Appointment Duration cannot be negative"],
      breakIntervalRules : [v=> v >= 0 || "Break Interval cannot be negative"],
      display_old_slots: [],
      selected_all : false,
      generate_slots_clicked : false,
      admin_data: "",
      specialization_list: [],
      selected_specialization: ""
    };
  },
  mounted() {
    this.pageName = "EditSlotsPage";
    this.currentUser = this.$cookies.get("token");
    if (!this.currentUser) {
      this.$router.push({
        name: "AdminLogin",
      });
    } else {
      var getAccessData = {
        token: this.currentUser,
        typeOfUser: "ADMIN"
      };
      axios_auth_instance_admin.post("/adminAssignDoctorTimingsPage", getAccessData)
      .then((adminAssignDoctorResponse) =>{
        this.admin_data = adminAssignDoctorResponse.data.admin_data;
         this.currentUserName = adminAssignDoctorResponse.data.admin_data.admin_name;
        this.specialization_list = adminAssignDoctorResponse.data.specialization_data;
        this.user_id = this.admin_data._id;
      })
      .catch((adminAssignDoctorError) =>{
        handleError(adminAssignDoctorError, this.$router, 'adminAssignDoctorError', 'AdminLogin', 'token');
      });
    }
  },
  components: {
    NavBar,
    DatePicker,
    SideBar
  },
  methods: {
    checkClass(slot)
    {
      let class_var = "inactive";
      if(slot.booked == 0)
        class_var = slot.selected == 1 ? 'active' : 'inactive'
      else
        class_var = 'booked';
      return class_var;
    },
    select_all(){
      this.generated_slots.forEach((x) =>{
        x.selected = 1;
      });
      this.selected_all = true
    },
    unselect_all(){
      this.generated_slots.forEach((x) =>{
        x.selected = 0;
      });
      this.selected_all = false
    },
    allowedDates(val){
      return this.display_old_slots.includes(val);
    },
    getPreviousSlots()
    {
      var old_slots_body = {
        "doctorId": this.selected_doctor._id,
        "token": this.currentUser,
        "typeOfUser": "ADMIN",
        "adminType": "ADMIN"
      };
      axios.post(process.env.VUE_APP_BACKEND_URL+"/getPreviouslyGeneratedSlotsDate",old_slots_body)
      .then((getOldSlotsResponse) =>{
        this.display_old_slots = [];
        this.old_slots = getOldSlotsResponse.data.data;
        this.old_slots.forEach((x) =>{
          this.display_old_slots.push((new Date(x).getFullYear()) +"-"+("0"+ (new Date(x).getMonth()+1)).slice(-2) +"-"+("0"+new Date(x).getDate()).slice(-2));
        });
        // this.show_old_slots = true;
      })
      .catch((getOldSlotsError) =>{
        if(getOldSlotsError.response.status == 401){
          window.alert("Sorry !! You are unauthorized !!");
          this.$router.push({
            name: "AdminLogin"
          });
        }
        else if(getOldSlotsError.response.status == 400){
          window.alert("Something went wrong!!");
        }
      });
    },
    getDoctorList() {
      this.doctors_list = [];
      var doctorData = {
        token: this.currentUser,
        typeOfUser: "ADMIN",
        specialization: this.selected_specialization._id,
      };
      axios
        .post(
          process.env.VUE_APP_BACKEND_URL + "/getDoctorsBySpecialization",
          doctorData
        )
        .then((specialistDataListResponse) => {
          this.doctors_list = specialistDataListResponse.data.data;
        })
        .catch((specialistDataListError) => {
          window.alert("Something went wrong");
        });
    },
    create_slots()
    {
      this.generate_slots_clicked = true
      this.selected_slots = this.generated_slots.filter((x) =>{
        return x.selected == 1;
      });
      let selected_slots_filtered = this.selected_slots.map(({time_slot, selected}) => (time_slot))
      /*let date = requestValues.date;
        if((!dateOfAppointment) || (!doctorId) || (!doctorName) || (!createdBy) || (!slotArray)){ */
      var edit_slots_body = {
        "date": this.start_gen_date,
        "updatedSlots": selected_slots_filtered,
        "doctorId": this.selected_doctor._id,
        "doctorName": this.selected_doctor.doctor_name,
        "appointmentDuration": this.appointment_duration,
        "breakDuration": this.break_duration,
        "updatedBy": this.user_id,
        "token": this.currentUser,
        "typeOfUser": "ADMIN",
      }
      axios.post(process.env.VUE_APP_BACKEND_URL+"/editSlots",edit_slots_body)
      .then((editSlotsResponse) =>{
        window.alert("Slots updated successfully");
        this.$router.go();
      })
      .catch((editSlotsError) =>{
        console.log(editSlotsError);
        window.alert("Error in updating slots")
      });
    },
    generate_slots()
    {
      this.generated_slots = [];
      var x = this.start_gen_date;
      x = moment(x).format("DD MMM YYYY");
      if(this.start_time < this.end_time)
      {
        if((this.old_slots.includes(x)))
        {
          if(this.start_time && this.end_time && this.selected_doctor)
          {
            let read_slot_body = {
              date: this.start_gen_date,
              doctorId: this.selected_doctor._id,
              token: this.currentUser,
              typeOfUser: "ADMIN",
            }
            axios.post(process.env.VUE_APP_BACKEND_URL+"/readSlots",read_slot_body)
            .then((readSlotsResponse) =>{
              this.generate_slots_clicked = false;
              this.appointment_duration = readSlotsResponse.data.appointment_duration;
              this.break_duration = readSlotsResponse.data.break_duration;
              this.old_generated_slots = readSlotsResponse.data.slots;
              var slots = bookingSlotGenerator.getSlotsSingle(this.start_time, this.end_time,parseInt(this.appointment_duration),parseInt(this.appointment_duration)+parseInt(this.break_duration));
              var obj = [];
              for (var i = 0; i < slots.length; ++i){
                obj[i] = {};
                obj[i]['time_slot'] = slots[i];
                obj[i]['selected'] = 0;
                obj[i]['booked'] = 0;
              }
              this.generated_slots = obj;
              this.generated_slots.map((x) => {
                // x.selected = this.old_generated_slots.includes(x.time_slot) ? 1 : 0;
                // x.booked = this.old_generated_slots.includes(x.time_slot) ? 1 : 0;
                let obj = this.old_generated_slots.find(o => o.time_slot == x.time_slot);
                if(obj)
                {
                  if(obj.counter == 0)
                  {
                    x.booked = 1;
                    x.selected = 0;
                  }
                  else
                  {
                    x.selected = 1;
                  }

                }
              });
            })
            .catch((readSlotsError) =>{
              console.log(readSlotsError);
            });
          }
          else
          {
            window.alert("Please select all the data");
          }
        }
        else
        {
          window.alert("No slots have been generated for Date - Doctor");
          console.log(x);
        }
      }
      else
      {
        window.alert("Please enter valid time slot")
      }
    },
    toggle(slot){
      if(slot.booked != 1)
      {
        if(slot.selected == 1)
         slot.selected = 0;
       else
         slot.selected = 1;
     }
    },
    gotoLoginPage() {
      var deleteData = {
        idToken: this.currentUser,
      };
      axios
      .post(process.env.VUE_APP_BACKEND_URL + "/deleteAdminToken", deleteData)
      .then((deleteResponse) => {
        this.$cookies.remove("token");
      })
      .catch((error) => {
      });
      localStorage.clear();
      this.$router.push({
        name: "AdminLogin",
      });
    },
  }
};
</script>

<style scoped>
  td,
  th {
    padding: 10px;

  }
  /* width */
  ::-webkit-scrollbar {
    width: 2px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  .active {
   background-color: #4baaf2;
 }
 .inactive {
   border: 1px solid lightgrey;
 }
 .booked {
  background-color: lightgrey;
 }
 #facebook{
  display: none;
}
/* .v-picker--full-width {
  height: 500px;
} */
 </style>
